.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.page-item {
  margin: 0 5px;
}

.page-item a {
  text-decoration: none;
  color: #1976d2;
  padding: 10px; /* Adjust padding to make circular */
  border: 1px solid #1976d2;
  border-radius: 50%; /* Make circular */
  width: 30px; /* Fixed width for circular shape */
  height: 30px; /* Fixed height for circular shape */
  display: flex; /* Center text */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.page-item a:hover {
  background-color: #007bff;
  color: white;
}

.active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.previous-item a,
.next-item a {
  background-color: #fff;
  color: #333;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  display: flex; /* Flexbox for vertical alignment */
  align-items: center; /* Center horizontally */
  cursor: pointer;
}

.previous-item a:hover,
.next-item a:hover {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
}
